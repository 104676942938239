import axios from "axios";

export default class Api{
	static baseUrl = process.env.REACT_APP_API_URL;

	static getProducts(){
		return axios.get(Api.baseUrl + "products");
	}
	static addProduct(product){
		return axios.post(Api.baseUrl + "products", {data:product.extract()});
	}
	static deleteProducts(skuList = []){
		return axios.delete(Api.baseUrl + "products", {data:skuList});
	}
}