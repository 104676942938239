import { useEffect, useState } from "react";
import Header from "../components/header";
import Product from "../components/products/product";
import Api from "../api";
import { useNavigate } from "react-router-dom";

function ProductList() {
	const navigate = useNavigate();
	const [products, setProducts] = useState([]);

	async function deleteProducts(){
		const deleteIds = [...document.querySelectorAll(".product")].filter(e=>e.querySelector(".delete-checkbox").checked)
		.map(e=>e.getAttribute("sku"));
	
		const res = await Api.deleteProducts(deleteIds);
		
		getProducts();

		return res.data;
	}

	async function getProducts(){
		const res = await Api.getProducts();		
		const newProducts = [];
	
		for(let key in res.data){
			const product = res.data[key];
	
			newProducts.push(Product.forType(product));
		}
		setProducts(newProducts);
	}

	useEffect(()=>{
		getProducts();
	}, []);

	return (
		<div>
			<Header title="Product List">
				<button className="white" onClick={()=>navigate("/add-product")}>
					ADD
				</button>
				<button id="delete-product-btn" className="red" onClick={()=>deleteProducts()}>
					MASS DELETE
				</button>
			</Header>
			<div className="product-content">
				{products.map(product=>product.displayObject(product.sku))}
			</div>
		</div>
	)
}

export default ProductList;