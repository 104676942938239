import Product from "./product";

export default class ProductDVD extends Product{
	formFields(){
		return [
			...super.formFields(),
			{tag: "div", props:{id:"DVD"}, content:[
				{
					name: "Size (MB)", tag: "input", props:{id:"size", type:"number"},
					errKey: "value",
					setValue: (value)=>this.value = value,
					getValue: ()=>this.value,
				},
				{name: "Please provide disc space in MB"},
			]}
		];
	}

	formatedDisplayValue(){
		return `Size: ${this.value} MB`;
	}
}