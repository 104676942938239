import { Route, Routes } from "react-router-dom";
import ProductList from "./pages/productList";
import ProductAdd from "./pages/productAdd";
import Footer from "./components/footer";

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<ProductList />}></Route>
				<Route path="/add-product" element={<ProductAdd />}></Route>
			</Routes>
			<Footer/>
		</>
	);
}

export default App;
