function Header({title = "", children}){
	return (
		<div className="header">
			<h1 className="title">
				{title}
			</h1>
			<div className="buttons">
				{children}
			</div>
		</div>
	)
}

export default Header;