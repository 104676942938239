export default class Product{
	constructor({sku = "", name = "", price = 0, type = "", value = ""}){
		this.sku = sku;
		this.name = name;
		this.price = parseFloat(price).toFixed(2);
		this.type = type;
		this.value = value;
	}

	extract(){
		return {
			sku: this.sku,
			name: this.name,
			price: +this.price,
			type: this.type,
			value: this.value,
		}
	}

	formFields(){
		return [
			{
				name: "SKU", tag: "input", props:{id:"sku"},
				setValue: (value)=>this.sku = value,
				getValue: ()=>this.sku,
			},
			{
				name: "Name", tag: "input", props:{id:"name"},
				setValue: (value)=>this.name = value,
				getValue: ()=>this.name,
			},
			{
				name: "Price", tag: "input", props:{id:"price", type:"number"},
				setValue: (value)=>this.price = value,
				getValue: ()=>this.price,
			},
			{
				name: "Type", tag: "select", options: Object.keys(productMap), props:{id:"productType"},
				setValue: (value)=>this.type = value,
				getValue: ()=>this.type,
			},
		];
	}

	formatedDisplayValue(){
		return this.value;
	}

	displayObject(key){
		return (
			<div className="product" key={key} sku={this.sku}>
				<div className="checkbox-container"><input type="checkbox" className="delete-checkbox"></input></div>
				<span>{this.sku}</span>
				<span>{this.name}</span>
				<span>{this.price}$</span>
				<span>{this.formatedDisplayValue()}</span>
			</div>
		)
	}

	static constructField(field, key, onInput, errorFields = []){
		let input = null;
		if(field.tag === "select"){
			input = <select className="field-input" {...field.props}
					defaultValue={field.getValue()}
					onChange={(e)=>onInput(e, field)}
					onInput={(e)=>onInput(e, field)}>
				<option disabled value={""} className="hidden"></option>
				{field.options.map((option,i)=>
					<option value={option} key={i}>{option}</option>
				)}
			</select>
		}else
		if(field.tag === "input"){
			input = <input className="field-input" {...field.props}
					defaultValue={field.getValue()}
					onInput={(e)=>onInput(e, field)}></input>
		}else
		if(field.tag === "div"){
			return (
				<div {...field.props} key={key}>
					{field.content.map((contentField, key)=>
						Product.constructField(contentField, field.props.id + key, onInput, errorFields))
					}
				</div>
			)
		}else{
			return (
				<p className="description" key={key}>{field.name}</p>
			)
		}
		const errKey = field.errKey || field.props.id;
		const errMessage = errorFields[errKey];
		let className = "product-form-field"
		if(errMessage) className += " invalid";

		return (
			<div key={key} className={className}>
				{
					errMessage && <span className="field-error">{errMessage}</span>
				}
				<span className="field-name">{field.name}</span>
				{input}
			</div>
		)
	}

	static forType(product, forgetValue = false){
		if(productMap[product.type]){
			const extras = forgetValue ? {value: ""} : {}
			return new productMap[product.type]({...product, ...extras});
		}
		return new Product(product);
	}
}

export const productMap = {
	"DVD" : require("./ProductBook").default,
	"Book": require("./ProductDVD").default,
	"Furniture": require("./ProductFurniture").default
};