import Product from "./product";

export default class ProductBook extends Product{
	formFields(){
		return [
			...super.formFields(),
			{tag: "div", props:{id:"Book"}, content:[
				{
					name: "Weight (KG)", tag: "input", props:{id:"weight", type:"number"},
					errKey: "value",
					setValue: (value)=>this.value = value,
					getValue: ()=>this.value,
				},
				{name: "Please provide book weight in KG"},
			]}
		];
	}

	formatedDisplayValue(){
		return `Weight: ${this.value} KG`;
	}
}