import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import { useState } from "react";
import Product from "../components/products/product";

import Api from "../api";
function ProductAdd() {
	const navigate = useNavigate();
	const [product, setProduct] = useState(new Product({}));
	const [errorFields, setErrorFields] = useState({});
	
	function onInput(e, field){
		field.setValue(e.target.value);
		
		if(field.props.id === "productType"){
			setProduct(Product.forType(product, true));
		}
		const errKey = field.errKey || field.props.id;
		if(errKey in errorFields){
			const newFields = {...errorFields};
			delete newFields[errKey];
			setErrorFields(newFields);
		}
	}

	async function save(){
		const res = await Api.addProduct(product);
		if(res.data.error){
			if(res.data.fields) setErrorFields(res.data.fields);
		}
		else navigate("/");
	}

	const formFields = product.formFields().map((field, i)=> Product.constructField(field, i, onInput, errorFields));

	return (
		<div>
			<Header title="Product Add">
				<button className="blue" onClick={()=>save()}>
					Save
				</button>
				<button className="white" onClick={()=>navigate("/")}>
					Cancel
				</button>
			</Header>
			<div id="product_form" className="product-form">
				{formFields}
				
			</div>
		</div>
	)
}

export default ProductAdd;