import Product from "./product";

export default class ProductFurniture extends Product{
	constructor(){
		super(...arguments);
		this.value = this.value.split("x");
		this.value.length = 3;
	}
	
	formFields(){
		return [
			...super.formFields(),
			{tag: "div", props:{id:"Furniture"}, content:[
				{
					name: "Height (CM)", tag: "input", props:{id:"height", type:"number"},
					errKey: "value0",
					setValue: (value)=>this.value[0] = value,
					getValue: ()=>this.value[0],
				},
				{
					name: "Width (CM)", tag: "input", props:{id:"width", type:"number"},
					errKey: "value1",
					setValue: (value)=>this.value[1] = value,
					getValue: ()=>this.value[1],
				},
				{
					name: "Length (CM)", tag: "input", props:{id:"length", type:"number"},
					errKey: "value2",
					setValue: (value)=>this.value[2] = value,
					getValue: ()=>this.value[2],
				},
				{name: "Please provide Furniture dimensions in HxWxL"},
			]}
		];
	}

	formatedDisplayValue(){
		return `Dimension: ${this.value.join("x")}`;
	}
}